<template>
  <v-dialog
    v-model="peerDialog"
    persistent
    max-width="600"
    overlay-opacity=".8"
  >
    <v-card class="pb-1">
      <v-card-title class="headline">
        {{ $t("card.select-peers") }}
      </v-card-title>
      <v-card-text v-if="myPeers && !axiosLoading">
        <v-row>
          <v-col
            cols="12"
            v-html="$t('text.to-whom-this-revision-request-is-submitted')"
          >
          </v-col>
          <v-col>
            <v-select
              class="mt-1"
              v-model="selectedPeers"
              :items="myPeers"
              item-text="nama"
              item-value="nik"
              outlined
              return-object
              v-bind:menu-props="{closeOnClick : true, closeOnContentClick : true}"
              single-line
              hide-details="true"
              clearable
              :label="$t('label.select-peers')"
              multiple
              chips
              hint="What are the target regions"
              persistent-hint
            ></v-select>
            <v-alert
              v-if="$v.selectedPeers.$error && !$v.selectedPeers.required"
              dense
              type="error"
              :icon="iconAlert"
              class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3"
            >
              {{ $t("error.broadcast-recipient-must-be-choosen") }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="!myPeers && !axiosLoading">
        <p>Peers is Empty or Server in Problem</p>
      </v-card-text>
      <v-card-text v-if="!myPeers && axiosLoading">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          width="100%"
          type="list-item-three-line, actions"
        ></v-skeleton-loader>
      </v-card-text>

      <v-card-actions v-if="myPeers && !axiosLoading">
        <v-spacer></v-spacer>
        <v-btn color="red darken-2" text @click="cancelSelect">
          <v-icon class="mr-1">{{ iconCancel }}</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>

        <v-btn color="blue darken-1" text @click="emitPeers">
          <v-icon class="mr-1">{{ iconSubmit }}</v-icon>
          {{ $t("button.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiAlertCircleOutline, mdiSend, mdiCloseCircleOutline } from "@mdi/js";
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");

export default {
  name: "peer-selector",
  props: {
    showPeerDialog: { type: Boolean },
  },
  data: () => ({
    selectedPeers: null,
    iconAlert: mdiAlertCircleOutline,
    iconSubmit: mdiSend,
    iconCancel: mdiCloseCircleOutline,
    //  items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
  }),
  mixins: [validationMixin],
  validations: {
    selectedPeers: { required },
  },
  computed: {
    ...mapState("resources", ["myPeers", "axiosLoading"]),
    items() {
      if (!this.myPeers) return null;
      return this.myPeers.map(({ nik }) => nik);
    },
    peerDialog: {
      get() {
        return this.showPeerDialog;
      },
      set(val) {
        this.$emit("showHidePeerDialog", val);
      },
    },
  },
  watch: {
    peerDialog(val) {
      if (!val) return;
      this.setMyPeers(undefined);
      this.getDataResource({ source: "peer-revisers" });
    },
  },
  methods: {
    ...mapActions("resources", ["getDataResource", "setMyPeers"]),
    emitPeers() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit("peerSelected", this.selectedPeers);
      this.$emit("showHidePeerDialog", false);

      setTimeout(() => {
        this.selectedPeers = null;
        this.$v.$reset();
      }, 500);
    },
    cancelSelect() {
      this.selectedPeers = null;
      this.$v.$reset();
      this.$emit("showHidePeerDialog", false);
    },
  },
};
</script>